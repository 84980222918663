<template>
  <!-- 页面整体布局 -->
  <div :class="classObj" class="app-wrapper">
    <!-- 左侧导航栏 -->
    <sidebar class="sidebar-container"></sidebar>
    <div class="phone">
      <div style="margin-bottom:10px">
        <span>服务热线：</span>
        <span>18351970306</span>
        <!-- <span>183****0306</span> -->
      </div>
      <div style="font-size:12px">
        <span>邮箱：</span>
        <span >feedback@deepthinking.net.cn</span>
        <!-- <span >feedback@******.net.cn</span> -->
      </div>
    </div>
    <div class="main-container">
      <div class="fixed-header">
        <!-- 头部工具栏 -->
        <navbar></navbar>
        <!-- 标签栏 -->
        <tags-view></tags-view>
      </div>
      <!--主窗口 -->
      <app-main></app-main>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'
  import {
    AppMain,
    Navbar,
    Sidebar,
    TagsView
  } from './components'

  export default {
    name: 'Layout',
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'sidebar'
      ]),
      classObj() {
        return {
          hideSidebar: !this.sidebar.opend,
          openSidebar: this.sidebar.opend
        }
      }
    },
    methods: {

    },
    components: {
      AppMain,
      Navbar,
      Sidebar,
      TagsView
    },
  }
</script>

<style lang='scss' scoped>
  // .app-wrapper {}
  @import "~@/styles/variables.scss";

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .phone {
    position: fixed;
    bottom: 15px;
    left: 5px;
    z-index: 1000;
    color: #b50b14;
    font-size: 12px;
  }
</style>