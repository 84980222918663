import router from "./router";
// 引入NProgress
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({
    showSpinner: false
})
import {
    getToken
} from './tools/auth' // get token from cookie

import axios from "axios";

const whiteList = ['/login', '/explanation', '/reject','/signature','/unsubscribe']
// ,'/unfinished/index','/finished/index'

const isNewVersion = () => {
    let url = `//${window.location.host}/static/version.json?t=${new Date().getTime()}`;
    axios.get(url).then(res => {
        if (res.status === 200) {
            let vueVersion = res.data.version;
            let localVueVersion = localStorage.getItem('vueVersion');
            if (localVueVersion && localVueVersion != vueVersion) {
                localStorage.setItem('vueVersion', vueVersion);
                window.location.reload();
                return;
            } else {
                localStorage.setItem('vueVersion', vueVersion);
            }
        }
    });
}

router.beforeEach((to, from, next) => {
    isNewVersion();
    NProgress.start();
    const login = getToken();
    if (login == 1) {
        next();
        NProgress.done();
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
            NProgress.done();
        } else {
            next({
                name: 'login'
            });
            NProgress.done();
        }

    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})