import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'


// 定义静态路由
export const asyncRoutes = [{
    path: '/',
    redirect: '/login',
    hidden: true
  }, {
    path: '/home',
    component: Layout,
    name: 'home',
    meta: {
      title: '主页',
      icon: 'list'
    },
    hidden: true,
    // redirect: 'err401',
    children: [{
      path: 'err401',
      name: 'err401',
      component: () => import('@/views/error/err401'),
      meta: {
        title: '错误页面',
        icon: 'el-icon-s-help'
      }
    }]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/login/login"),
    hidden: true
  },
  {
    path: '/unfinished',
    name: 'unfinished',
    component: Layout,
    meta: {
      title: '论文评审',
      icon: 'el-icon-reading'
    },
    children: [{
      path: 'index',
      name: 'unfinishedList',
      component: () => import('@/views/unfinished/UnfinishedList'),
      meta: {
        title: '论文评审',
        // icon:'el-icon-star-on'
      }
    }]
  }, {
    path: '/finished',
    component: Layout,
    name: 'finished',
    // redirect: '/schoolCustom/index',
    meta: {
      title: '历史记录',
      icon: 'el-icon-folder'
    },
    children: [{
      path: 'index',
      name: 'finishedList',
      component: () => import('@/views/finished/FinishedList'),
      meta: {
        title: '历史记录',
        // icon: 'el-icon-s-help'
      }
    }]
  }, {
    path: '/finances',
    component: Layout,
    name: 'finances',
    // redirect: '/schoolCustom/index',
    meta: {
      title: '评审费用',
      icon: 'el-icon-tickets'
    },
    children: [{
      path: 'index',
      name: 'financeList',
      component: () => import('@/views/finances/FinanceList'),
      meta: {
        title: '评审费用',
        // icon: 'el-icon-s-help'
      }
    }]
  }, {
    path: '/personalInfo',
    component: Layout,
    // name: 'personalInfo',
    // redirect: '/schoolCustom/index',
    meta: {
      title: '个人信息',
      icon: 'el-icon-user'
    },
    children: [{
        path: 'index',
        name: 'personalInfo',
        component: () => import('@/views/personalInfo/PersonalInfo'),
        meta: {
          title: '个人信息',
        }
      },
      {
        path: 'password',
        name: 'password',
        component: () => import('@/views/personalInfo/password'),
        meta: {
          title: '修改密码',
        }
      }
    ]
  }, {
    path: '/review/:paperId/:reviewId',
    name: 'reviewPage',
    component: () => import('@/views/unfinished/ReviewPage'),
    meta: {
      title: '评审页面',
      icon: 'el-icon-s-help'
    },
    hidden: true
    // children: [{
    //   path: 'index',
    //   name: 'reviewPage',
    //   component: () => import('@/views/unfinished/ReviewPage'),
    //   meta: {
    //     title: '评审页面',
    //     icon: 'el-icon-s-help'
    //   },
    //   hidden:true
    // }],
    // hidden:true
  }, {
    path: '/explanation',
    component: () => import('@/views/user/explanation'),
    meta: {
      title: '接审页面',
      icon: 'list'
    },
    hidden: true
  }, {
    path: '/reject',
    component: () => import('@/views/user/reject'),
    meta: {
      title: '拒绝页面',
      icon: 'list'
    },
    hidden: true
  },{
    path: '/signature',
    component: () => import('@/views/user/signature'),
    meta: {
      title: '电子签名',
      icon: 'list'
    },
    hidden: true
  },{
    path: '/unsubscribe',
    component: () => import('@/views/user/unsubscribe'),
    meta: {
      title: '取消订阅页面',
      icon: 'list'
    },
    hidden: true
  },
]

// 定义创建路由函数
const createRoute = () => new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: asyncRoutes
})


// const router = new VueRouter({
//   constantRoutes
// })

// 调用创建路由函数
const router = createRoute()

export default router