import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const finance = {
    // 获取已支付金额和论文数量
    getWithdrawn()
    {
        return get('/expert/get_withdrawn');
    },
    // 获取专家未提现余额和个数
    getExpertBalance(){
        return get('/expert/get_expert_balance');
    },
    // 获取专家账单
    getExpertBills(params)
    {
        return get('/expert/get_expert_bills',params);
    }
};

export default finance