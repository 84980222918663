const state = {
    paperInfo:{
        name:'',
        type:''
    }
}
const mutations = {
    changePaperInfo(state,paperInfo){
        state.paperInfo = paperInfo;
    }
}
const actions = {
    changePaperInfo({commit},paperInfo){
        commit('changePaperInfo',paperInfo);
    }
}

export default {
    namespaced:true,
    state:state,
    mutations:mutations,
    actions:actions
}