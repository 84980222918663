const state = {
    isChangeDiaVis:false,
    isLogin: 0,
    info:{
        name:'',
        institution:''
    },
    account:''
};
const mutations = {
    changeIsChangeDiaVis:(state, isChangeDiaVis)=>{
        state.isChangeDiaVis = isChangeDiaVis;
    },changeIsLogin(state, isLogin) {
        state.isLogin = isLogin;
    },
    changeInfo(state, value) {
        state.info = value;
    },
    changeAccount(state, value) {
        state.account = value;
    },
};
const actions = {
    changeIsChangeDiaVis({commit}, isChangeDiaVis) {
        commit('changeIsChangeDiaVis', isChangeDiaVis);
    },
    changeIsLogin(context, isLogin) {
        context.commit('changeIsLogin', isLogin);
    },
    changeInfo(context, value) {
        context.commit('changeInfo', value);
    },
    changeAccount(context, value) {
        context.commit('changeAccount', value);
    },
};

export default {
    namespaced: true,
    state:state,
    mutations:mutations,
    actions:actions
}