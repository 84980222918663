import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const reviewPage = {
    // 获取论文原件评审信息
    getPaper(params) {
        return get('/expert/getPaper', params);
    },
    // 获取特殊字符替换map
    getReplaceMap(){
        return get('/expert/get_replaceMap');
    },
    // 保存评阅书信息
    postSaveReviewContent(params) {
        return post('/expert/saveReviewContent', params);
    },
    
    getPaperRaw(params)
    {
        return http.get('/expert/download_file',{
            params:params,
            responseType: 'blob'
          }); 
    },
    // 获取电子签名
    getSignature(params)
    {
        return get("/expert/get_signature",params);
    },
    // 保存电子签名
    postSaveSignature(params)
    {
        return post("/expert/save_signature",params);
    },
    // 通过手写签名
    mobileSaveSignature(params){
        return post('expert/mobileSaveSignature',params);
    },

    // 轮询
    mobileSaveSignatureStateCheck(params){
        return post("/expert/mobileSaveSignatureStateCheck",params);
    },
    // zxr 下载附件
    downloadFile(params){
        // return get('/paper/downloadPaperAnnex',params);
        return http.get('/paper/downloadPaperAnnex',{
            // paperId: params.paperId,
            params: {paperId:params.paperId},
            responseType: 'blob'
        });
    }
}

export default reviewPage