import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const user = {
    // 获取用户信息
    getUser() {
        return get('/expert/get_expertInfo')
    },
    // 登录
    login(params) {
        return http.post('/login', params);
    },
    // 点击接审链接
    getToken(params) {
        return get('/token', params);
    },
    // 点击拒绝链接
    getReject(params) {
        return get('/token', params);
    },
    // 获取token
    getExpertToken(){
        return post('/expert/getToken');
    },
    postPassword(params){
        return get('',params)
    },
    // 退出
    logout() {
        return post('/logout');
    },
    // 修改密码
    postEditPwd(params)
    {
        return post('/user/updateUserPassword',params);
    }
}

export default user