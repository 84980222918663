<template>
    <!-- 侧边栏 -->
    <div :class="{'has-logo':showLogo}">
        <logo :collapse='isCollapse'></logo>
        <el-scrollbar wrap-class='scrollbar-wrapper'>
            <el-menu :default-active="activeMenu" :collapse='isCollapse' :background-color="variables.menuBg"
                :text-color="variables.menuText" :unique-opened="false" :active-text-color="variables.menuActiveText"
                :collapse-transition='true' mode="vertical">
                <!-- <el-menu-item index="index"></el-menu-item> -->
                <sidebar-Item v-for='route in routes' :key="route.path" :item='route' :basePath='route.path'>
                </sidebar-Item>
            </el-menu>
        </el-scrollbar>
        
    </div>
</template>

<script>
    import {
        asyncRoutes
    } from '@/router/index'
    import SidebarItem from './SidebarItem.vue'
    import Logo from './Logo'
    import variables from '@/styles/variables.scss'
    import {
        mapGetters
    } from 'vuex'
    export default {
        name: 'Sidebar',
        data() {
            return {
                routes: asyncRoutes,
            }
        },
        computed: {
            ...mapGetters([
                'sidebar'
            ]),
            activeMenu() {
                const route = this.$route;
                const {
                    meta,
                    path
                } = route;
                if (meta.activeMenu) {
                    return meta.activeMenu;
                }
                return path;
            },
            variables() {
                return variables;
            },
            showLogo() {
                return true;
            },
            //导航栏是否折叠
            isCollapse() {
                return !this.sidebar.opend;
            }
        },
        methods: {

        },
        components: {
            SidebarItem,
            Logo
        },

    }
</script>

<style scoped>

</style>