import unfinished from "./unfinished";
import user from "./user";
import reviewPage from "./reviewPage";
import finance from '@/api/finance'
import info from "./info";

export default {
    unfinished,
    user,
    reviewPage,
    finance,
    info
}