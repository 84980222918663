import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 引入elementui 
import Element from 'element-ui'
import './styles/element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css';
// 引入自定义样式
import './styles/index.scss'
Vue.use(Element)

// 引入vxe-table
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)

import permission from './permission'
// import '@/icons'

// 引入api
import api from './api/index'
Vue.prototype.$api = api;

//画板引入
import vueEsign from 'vue-esign'
Vue.use(vueEsign)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')