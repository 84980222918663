import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const info = {
    // 获取专家信息
    getExpert() {
        return get('/expert/getExpert');
    },
    // 修改专家信息
    postCorrectInfo(params) {
        return post('/expert/correctInfo', params);
    },
    // 检查信息完整性
    getCheckInfo(params) {
        return get('/expert/checkInfo', params);
    },
    // 获取院校信息
    getUniversity(){
        return get('/expert/get_university');
    },
    // 获取学科信息
    getAllSubject(){
        return get('/expert/get_all_subjects');
    }

};

export default info