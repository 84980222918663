import axios from "axios";
import baseUrl from '@/api/http/baseUrl'
import {removeToken} from '@/tools/auth'
import {
    Message
} from "element-ui";

const http = axios.create({
    baseURL: '/api'

});

http.interceptors.request.use(
    config => {
        // 验证是否登录
        return config;
    }, err => {
        Promise.error(err);
    }
)

http.interceptors.response.use(
    res => {
        if (res.status === 200) {
            if (res.data.Message == 'Login is needed') {
                // router.push({
                //     name: 'login'
                // })
                removeToken();
            }
            return Promise.resolve(res);
        } else {
            return Promise.reject(res);
        }
    },
    err => {
        const {
            response
        } = err;
        if (response) {
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            Message.error("网络中断");
        }
    }
)

function errorHandle(statue, message) {
    // 状态码判断
    switch (statue) {
        case 401:
            Message.error("错误1");
            break;
        case 402:
            Message.error("错误2");
            break;
        default:
            Message.error(message);
    }
}

function get(url, params = null) {
    if (params) return http.get(url, {
        params: params
    });
    return http.get(url);
}

function post(url, params) {
    return http.post(url, params);
}

export default {
    http,
    get,
    post
};