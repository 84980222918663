<template>
  <!-- 页面中心区域 -->
  <section class="app-main">
    <transition name='fade-transform' mode="out-in">
      <router-view></router-view>
    </transition>
  </section>
</template>

<script>
  export default {
    name: 'AppMain',
    data() {
      return {

      }
    },
    methods: {

    },
    components: {

    }
  }
</script>

<style lang='scss' scoped>
  .app-main {
    min-height: calc(100vh - 50px);
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .fixed-header+.app-main {
    padding-top: 50px;
  }
</style>