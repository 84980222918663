import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const unfinished = {
    //获取待评审论文列表
    getReviewingPaperList()
    {
        return get('/expert/get_ReviewingPaperList');
    },
    // 获取已评审论文列表
    getReviewedRecords(params)
    {
        return get('/expert/get_ReviewedRecords',params);
    },
    // 下载pdf
    downloadFile(params){
        return http.get('/expert/download_file',{
            params: params,
            responseType: 'blob'
        });
    },
    // zxr 下载附件
    downloadAnnexFile(params){
        // return get('/paper/downloadPaperAnnex',params);
        return http.get('/paper/downloadPaperAnnex',{
            // paperId: params.paperId,
            params: {paperId:params.paperId},
            responseType: 'blob'
        });
    }
}

export default unfinished;